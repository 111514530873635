<template>
  <div>
    <v-card class="pa-4 mb-4" color="transparent" elevation="0">
      <!-- Reference type template -->
      <template v-if="props.loadedCase?.type === 'Reference'">
        <v-card-title class="pl-4" style="white-space: pre-wrap; word-break: break-word;">
          Reference Annotation Template
        </v-card-title>
        <v-card-subtitle class="pl-4">
          {{ props.loadedCase.name }}
        </v-card-subtitle>
        <div class="pl-4 mt-2" style="font-size: 14px; line-height: 1.6;">
          These template measurements can then be applied to existing and new cases.
          Add new annotations in Annotations and store them by pressing 'Upload'.
        </div>
        <div class="d-flex align-center ml-4 mt-4">
          <v-btn @click="uploadReferenceAnnotations(props.loadedCase.id)" class="mr-2">
            <v-icon left>mdi-upload</v-icon>
            Upload New Annotations
          </v-btn>
          <v-icon v-if="refreshSuccess" color="green" small>mdi-check-circle</v-icon>
        </div>
      </template>

      <!-- Cohort type template -->
      <template v-else-if="props.loadedCase?.type === 'Cohort'">
        <v-card-title class="pl-4" style="white-space: pre-wrap; word-break: break-word;">
          Cohort Information
        </v-card-title>
        <v-card-subtitle class="pl-4">
          Name: {{ props.loadedCase.name }}
        </v-card-subtitle>
        <div class="pl-4 mt-2" style="font-size: 14px; line-height: 1.6;">
          Cohorts are a collection of examples in correspondence with a reference template of a specific anatomy.
          You can create cohorts above and a worker either build the virtual samples or collects the requested cases.
        </div>
      </template>

      <!-- Regular case template -->
      <template v-else>
        <v-card-title class="pl-4" style="white-space: pre-wrap; word-break: break-word;">
          {{ props.loadedCase?.name }}
        </v-card-title>
        <v-card-subtitle class="pl-4">
          Task: {{ displayTask }}
        </v-card-subtitle>
        
        <!-- Refresh Case Button -->
        <v-btn 
          v-if="props.loadedCase?.type !== 'Reference'"
          @click="refreshCase"
          class="ml-4 mt-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          Refresh Case
        </v-btn>

        <!-- Upload & Submit button for non-finished jobs -->
        <v-btn 
          v-if="!isJobFinished(props.loadedCase?.meta?.state) && props.loadedCase?.type !== 'Reference'"
          @click="uploadData(props.loadedCase?._id, props.loadedCase?.meta?.task)"
          class="ml-4 mt-2"
        >
          <v-icon left>mdi-upload</v-icon>
          Upload & Submit
        </v-btn>

        <!-- New Correct button for specified tasks -->
        <v-btn 
          v-if="canCorrect && isJobFinished(props.loadedCase?.meta?.state) && props.loadedCase?.type !== 'Reference'"
          @click="correctData(props.loadedCase?.id)"
          class="ml-4 mt-2"
          color="primary"
        >
          <v-icon left>mdi-pencil</v-icon>
          Correct
        </v-btn>

        <!-- Job details section -->
        <job-details-card 
          v-if="jobIsAvailable && props.loadedCase?.type !== 'Reference'"
          :job-details="jobDetails"
          :job-log="jobLog"
          @show-log="$emit('show-log')"
        />
      </template>
    </v-card>
  </div>
</template>

<script setup>
import { computed, watch, onMounted, ref } from 'vue';
import { useGirderStore } from '@/src/store/girder-store';
import { useDatasetStore } from '@/src/store/datasets';
import { useModelStore } from '@/src/store/datasets-models';
import { useSurfaceMeasurementStore } from '@/src/store/tools/surfaceMeasurements';
import { useMessageStore } from '@/src/store/messages';
import { progressCallback } from '@/src/store/progress-store';
import JobDetailsCard from './JobDetailsCard.vue';

const props = defineProps({
  loadedCase: {
    type: Object,
    required: true
  },
  jobDetails: {
    type: Object,
    default: () => null
  }
});

const emit = defineEmits(['show-log']);

const girderStore = useGirderStore();
const datasetStore = useDatasetStore();
const modelStore = useModelStore();
const refreshSuccess = ref(false);
const currentJobState = ref(null);

const jobIsAvailable = computed(() => !!props.jobDetails);
const jobLog = computed(() => (props.jobDetails?.log || []).join('\n'));

// Add computed property for task display
const displayTask = computed(() => {
  if (!props.loadedCase) return 'No case loaded';
  if (props.loadedCase.type === 'Reference' || props.loadedCase.name === 'Reference') return 'Reference Template';
  if (props.loadedCase.type === 'Cohort') return 'Cohort';
  if (!props.loadedCase.meta) return 'No metadata available';
  return props.loadedCase.meta.task || 'No task selected';
});

const canCorrect = computed(() => {
  if (!props.loadedCase) return false;
  if (props.loadedCase.type === 'Reference' || props.loadedCase.name === 'Reference') return false;
  if (props.loadedCase.type === 'Cohort') return false;

  const task = props.loadedCase?.meta?.task;
  const allowedTasks = ['Registration', 'Auto measurements'];
  return allowedTasks.includes(task);
});

const isJobFinished = (state) => {
  if (!props.loadedCase) return false;
  if (props.loadedCase.type === 'Reference' || props.loadedCase.name === 'Reference') return true;
  if (props.loadedCase.type === 'Cohort') return true;

  if (state === undefined) return false;
  return state !== 'state_empty';
};

const uploadData = async (caseId, task) => {
  const messageStore = useMessageStore();
  try {
    await girderStore.uploadData(caseId, task, progressCallback);
    messageStore.addSuccess("Successfully uploaded data");
  } catch (error) {
    messageStore.addError("Failed to upload data: " + error.message);
  }
};

const correctData = async (caseId) => {
  const messageStore = useMessageStore();
  try {
    await girderStore.correctData(caseId, progressCallback);
    messageStore.addSuccess("Successfully corrected data");
  } catch (error) {
    messageStore.addError("Failed to correct data: " + error.message);
  }
};

const uploadReferenceAnnotations = async (girderPath) => {
  const messageStore = useMessageStore();
  try {
    const measurementStore = useSurfaceMeasurementStore();
    const { measurements } = measurementStore.serializeMeasurements();
    const metadata = { measurements };
    await girderStore.updateMetadata(girderPath, metadata);
    messageStore.addSuccess("Successfully uploaded reference annotations");
    refreshSuccess.value = true;
    setTimeout(() => {
      refreshSuccess.value = false;
    }, 3000);
  } catch (error) {
    messageStore.addError("Failed to upload reference annotations: " + error.message);
  }
};

watch(() => props.jobDetails?.status, async (newStatus, oldStatus) => {
  if (props.loadedCase && oldStatus) {
    if (newStatus === 3 && oldStatus !== 3) {
      const messageStore = useMessageStore();
      messageStore.addSuccess('Job completed. Downloading updated case data...');
      await girderStore.downloadCase(props.loadedCase.id, progressCallback, true);
    }
  }
  
  currentJobState.value = newStatus;
});

onMounted(() => {
  currentJobState.value = props.jobDetails?.status || null;
});

const refreshCase = async () => {
  const messageStore = useMessageStore();
  try {
    if (props.loadedCase) {
      messageStore.addSuccess('Refreshing case data...');
      await girderStore.downloadCase(props.loadedCase.id, progressCallback, true);
      messageStore.addSuccess('Refresh completed');
    } else {
      messageStore.addError('No case loaded to refresh');
    }
  } catch (error) {
    messageStore.addError('Refresh failed - ' + error.message);
  }
};
</script>

<style scoped>
:deep(.v-card-title) {
  font-size: 1.25rem;
  font-weight: 500;
}

:deep(.v-card-subtitle) {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}
</style> 