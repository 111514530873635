<template>
  <v-dialog 
    scrollable 
    :model-value="modelValue" 
    @update:model-value="$emit('update:modelValue', $event)"
    height="80%" 
    max-width="60%"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Open Case</span>
        <v-row class="mt-2">
          <v-col cols="12" sm="4">
            <v-select 
              label="Anatomy" 
              :items="anatomies" 
              v-model="anatomyFilter" 
              item-value="value" 
              item-title="text"
              clearable 
              :loading="isSearching"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select 
              label="Task" 
              :items="tasks" 
              v-model="taskFilter" 
              item-value="task" 
              item-title="task"
              clearable 
              :loading="isSearching"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select 
              label="State" 
              :items="states" 
              v-model="stateFilter" 
              item-value="state" 
              item-title="state"
              clearable 
              :loading="isSearching"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="4">
            <v-text-field 
              v-model="searchQuery" 
              label="Search" 
              clearable 
              :loading="isSearching"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-progress-linear
        v-if="isDownloading"
        :model-value="downloadProgress"
        color="primary"
        height="4"
        striped
      ></v-progress-linear>

      <v-card-text class="scrollable-content">
        <v-data-table 
          :items="filteredCasesList" 
          :headers="headers"
          :loading="isSearching"
          loading-text="Loading cases..."
          item-key="caseId" 
          v-model:sort-by="sortBy"
          v-model:sort-desc="sortDesc"
          :items-per-page="itemsPerPage"
          @update:options="handleTableUpdate"
        >
          <template v-slot:item.state="{ item }">
            <v-progress-circular 
              :model-value="getStateProgress(item)" 
              :color="getStateColor(item)"
              :size="24"
              :width="3"
            >
              <template v-slot:default>
                <v-tooltip activator="parent">
                  <template v-slot:default>
                    {{ getJobStatusText(item) }}
                  </template>
                </v-tooltip>
              </template>
            </v-progress-circular>
          </template>

          <template v-slot:item.task="{ item }">
            <v-chip v-if="item.task" small color="blue" class="mr-4">
              {{ item.task }}
            </v-chip>
          </template>

          <template v-slot:item.created="{ item }">
            {{ new Date(item.created).toLocaleString() }}
          </template>

          <template v-slot:item.lastUpdated="{ item }">
            {{ item.lastUpdated ? new Date(item.lastUpdated).toLocaleString() : 'Not yet run' }}
          </template>

          <template v-slot:item.action="{ item }">
            <v-btn 
              @click="downloadCase(item.id)"
              :loading="isDownloading && item.id === downloadingCaseId"
              :disabled="isDownloading"
            >
              <v-icon left>mdi-download</v-icon>
              Download
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:modelValue', false)" :disabled="isDownloading">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useGirderStore } from '@/src/store/girder-store';
import { useFileStore } from '@/src/store/datasets-files';
import { useDatasetStore } from '@/src/store/datasets';
import { useModelStore } from '@/src/store/datasets-models';
import { useSurfaceMeasurementStore } from '@/src/store/tools/surfaceMeasurements';
import debounce from 'lodash/debounce';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const girder = useGirderStore();
const datasetStore = useDatasetStore();
const modelStore = useModelStore();
const surfaceStore = useSurfaceMeasurementStore();

// State
const searchQuery = ref('');
const taskFilter = ref('');
const stateFilter = ref('');
const anatomyFilter = ref('');
const itemsPerPage = ref(10);
const currentPage = ref(1);
const isSearching = ref(false);
const isLoading = ref(false);
const downloadProgress = ref(0);
const isDownloading = ref(false);
const downloadingCaseId = ref(null);

// Add these refs for sort state management
const sortBy = ref(['created']);
const sortDesc = ref([true]);

// Constants
const tasks = [
  'Auto measurements',
  'Prepare for simulation',
  'Reconstruct',
  'Fit device',
  'Registration',
  'Foot Fitting'
];

const states = ['state_empty', 'state_image_uploaded', 'state_image_processed'];

const headers = [
  { title: 'Name', key: 'name', sortable: true },
  { title: 'Anatomy', key: 'anatomy', sortable: true },
  { title: 'Task', key: 'task', sortable: true },
  { title: 'State', key: 'state', sortable: true },
  { title: 'Created', key: 'created', sortable: true },
  { title: 'Last Updated', key: 'lastUpdated', sortable: true },
  { title: 'Action', key: 'action', sortable: false }
];

// Computed
const anatomies = computed(() => girder.anatomies);
const casesList = computed(() => girder.cases);

const filteredCasesList = computed(() => {
  let filtered = Object.values(casesList.value).filter((caseItem) => {
    const matchesTask = taskFilter.value ? caseItem.meta?.task === taskFilter.value : true;
    const matchesState = stateFilter.value ? caseItem.state === stateFilter.value : true;
    const matchesAnatomy = anatomyFilter.value ? caseItem.meta?.anatomy === anatomyFilter.value : true;
    const searchQ = searchQuery.value ? searchQuery.value.toLowerCase() : '';
    const matchesSearch = caseItem.name.toLowerCase().includes(searchQ);

    return matchesTask && matchesState && matchesAnatomy && matchesSearch;
  });

  // Handle sorting
  if (sortBy.value.length && sortBy.value[0]) {
    const sortKey = sortBy.value[0];
    const sortDirection = sortDesc.value[0] ? -1 : 1;
    
    filtered.sort((a, b) => {
      let aVal = sortKey === 'created' || sortKey === 'lastUpdated' 
        ? new Date(a[sortKey] || 0).getTime()
        : sortKey === 'task' || sortKey === 'anatomy'
          ? a.meta?.[sortKey] || ''
          : a[sortKey] || '';
          
      let bVal = sortKey === 'created' || sortKey === 'lastUpdated'
        ? new Date(b[sortKey] || 0).getTime()
        : sortKey === 'task' || sortKey === 'anatomy'
          ? b.meta?.[sortKey] || ''
          : b[sortKey] || '';
          
      if (aVal < bVal) return -1 * sortDirection;
      if (aVal > bVal) return 1 * sortDirection;
      return 0;
    });
  }

  return filtered.map((caseItem) => ({
    name: caseItem.name,
    anatomy: caseItem.meta?.anatomy,
    task: caseItem.meta?.task,
    state: caseItem.state,
    created: caseItem.created,
    lastUpdated: caseItem.meta?.jobUpdated,
    meta: caseItem.meta,
    id: caseItem.id,
    action: caseItem.id
  }));
});

const selectedImageID = computed(() => {
  const { primarySelection } = datasetStore;
  if (primarySelection?.type === 'image') {
    return primarySelection.dataID;
  }
  if (primarySelection?.type === 'dicom') {
    return primarySelection.volumeKey || null;
  }
  return null;
});

const selectedMeshID = computed(() => modelStore.currentId);
const validFileLoaded = computed(() => selectedImageID.value !== null || selectedMeshID.value !== null);

// Methods
const handleTableUpdate = async (options) => {
  sortBy.value = options.sortBy?.length ? options.sortBy : ['created'];
  sortDesc.value = options.sortDesc?.length ? options.sortDesc : [true];
  
  currentPage.value = options.page || 1;
  itemsPerPage.value = options.itemsPerPage || 10;
  
  try {
    isSearching.value = true;
    await girder.listCases();
    
    // Get the current page's items and update their job details
    const startIndex = (currentPage.value - 1) * itemsPerPage.value;
    const endIndex = startIndex + itemsPerPage.value;
    const visibleItems = filteredCasesList.value.slice(startIndex, endIndex);
    
    // Update job details for all visible items that have jobs
    for (const item of visibleItems) {
      if (item.meta?.jobId) {
        await girder.updateCaseWithJobDetails(item.id);
      }
    }
  } finally {
    isSearching.value = false;
  }
};

const handleSearch = debounce(async () => {
  if (!searchQuery.value && !taskFilter.value && !stateFilter.value && !anatomyFilter.value) {
    await handleTableUpdate({ page: currentPage.value, itemsPerPage: itemsPerPage.value });
    return;
  }

  isSearching.value = true;
  try {
    await girder.listAllCases();
  } finally {
    isSearching.value = false;
  }
}, 300);

const getJobStatusText = (item) => {
  if (!item.meta?.jobStatus) return 'No job status';
  
  const status = parseInt(item.meta.jobStatus);
  switch (status) {
    case 0: return 'Inactive';
    case 1: return 'Queued';
    case 2: return 'Running';
    case 3: return 'Success';
    case 4: return 'Error';
    case 5: return 'Canceled';
    default: return 'Unknown';
  }
};

const getStateProgress = (item) => {
  if (item.meta?.jobStatus !== undefined) {
    const status = parseInt(item.meta.jobStatus);
    switch (status) {
      case 3: return 100;  // Success
      case 4: return 100;  // Error
      case 5: return 100;  // Canceled
      case 2: return item.meta?.jobProgress || 50;   // Running - use actual progress if available
      case 1: return 0;   // Queued
      case 0: return 0;    // Inactive
      default: return 0;
    }
  }
  
  if (item.state === 'state_image_processed') return 100;
  if (item.state === 'state_image_uploaded') return 50;
  if (item.state === 'state_empty') return 0;

  return 0;
};

const getStateColor = (item) => {
  if (item.meta?.jobStatus !== undefined) {
    const status = parseInt(item.meta.jobStatus);
    switch (status) {
      case 3: return 'success';  // Success
      case 4: return 'error';    // Error
      case 5: return 'warning';  // Canceled
      case 2: return 'primary';  // Running
      case 1: return 'info';     // Queued
      case 0: return 'grey';     // Inactive
      default: return 'grey';
    }
  }
  return 'grey';
};

const downloadCase = async (caseId) => {
  try {
    isDownloading.value = true;
    downloadProgress.value = 0;
    downloadingCaseId.value = caseId;
    
    const progressCallback = (progress) => {
      downloadProgress.value = progress;
    };

    // Get case data and ensure input/output items are properly parsed
    const caseData = girder.cases[caseId];
    if (!caseData) {
      throw new Error('Case not found');
    }

    // Parse input_items and output_items if they're strings
    if (caseData.meta) {
      try {
        if (typeof caseData.meta.input_items === 'string') {
          caseData.meta.input_items = JSON.parse(caseData.meta.input_items);
        }
        if (typeof caseData.meta.output_items === 'string') {
          caseData.meta.output_items = JSON.parse(caseData.meta.output_items);
        }
      } catch (e) {
        console.error('Error parsing items:', e);
        caseData.meta.input_items = [];
        caseData.meta.output_items = [];
      }
    }

    surfaceStore.deleteAllMeasurements();
    datasetStore.deleteAllData();
    girder.resetPaths();
    girder.setLoadedCaseByID(caseId);
    await girder.downloadCase(caseId, progressCallback);
    emit('update:modelValue', false);
  } catch (error) {
    console.error('Failed to download case:', error);
  } finally {
    isDownloading.value = false;
    downloadProgress.value = 0;
    downloadingCaseId.value = null;
  }
};

const uploadData = async (caseId, task) => {
  try {
    await girder.uploadData(caseId, task);
  } catch (error) {
    console.error('Failed to upload data:', error);
  }
};

// Watch for filter changes
watch([searchQuery, taskFilter, stateFilter, anatomyFilter], handleSearch);

// Watch for dialog visibility
watch(() => props.modelValue, async (newValue) => {
  if (newValue) {
    isLoading.value = true;
    try {
      await handleTableUpdate({ page: 1, itemsPerPage: itemsPerPage.value });
    } finally {
      isLoading.value = false;
    }
  }
});
</script>

<style scoped>
.scrollable-content {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
}

:deep(.v-progress-circular) {
  margin: 0 8px;
}

:deep(.v-progress-circular--indeterminate) {
  animation: progress-circular-rotate 1s linear infinite;
}
</style> 