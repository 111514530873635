<template>
  <div>
    <v-card-title>
      <span class="headline">Create New Cohort</span>
    </v-card-title>
    <v-card-text>
      <v-form>
        <!-- Cohort Name -->
        <v-text-field
          label="Cohort Name"
          v-model="newCohortName"
          required
        ></v-text-field>

        <!-- Anatomy Selection -->
        <v-select
          label="Anatomy"
          :items="anatomies"
          v-model="selectedAnatomy"
          item-title="text"
          item-value="value"
          required
        ></v-select>

        <!-- Device Option -->
        <v-checkbox
          label="Show Fitted Device"
          v-model="showFittedDevice"
          @change="handleDeviceChange"
        ></v-checkbox>

        <!-- Virtual Cohort Switch -->
        <v-switch
          label="Virtual Cohort"
          v-model="isVirtual"
          :disabled="showFittedDevice"
        ></v-switch>

        <!-- Number of Samples (Only for Virtual Cohorts) -->
        <v-slider
          v-if="isVirtual"
          label="Number of Samples"
          v-model="sampleCount"
          :step="1"
          :min="1"
          :max="100"
          required
        >
          <template v-slot:append>
            <v-text-field
              v-model="sampleCount"
              type="number"
              class="mt-0"
              style="width: 100px;"
            ></v-text-field>
          </template>
        </v-slider>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="createCohort">
        <v-icon left>mdi-plus-box</v-icon>
        Create
      </v-btn>
      <v-btn color="secondary" @click="$emit('close')">
        <v-icon left>mdi-cancel</v-icon>
        Cancel
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import { ref, computed } from 'vue';
import { useGirderStore } from '../store/girder-store';
import { useMessageStore } from '../store/messages';

export default {
  name: 'CohortCreator',
  emits: ['close', 'created'],
  setup(_, { emit }) {
    const girder = useGirderStore();
    const messageStore = useMessageStore();

    const newCohortName = ref('');
    const selectedAnatomy = ref('');
    const isVirtual = ref(false);
    const sampleCount = ref(0);
    const showFittedDevice = ref(false); // New device flag

    const anatomies = computed(() => girder.anatomies);

    // Disable 'Virtual Cohort' when device is checked
    const handleDeviceChange = () => {
      if (showFittedDevice.value) {
        isVirtual.value = false;
      }
    };

    const createCohort = async () => {
      if (!newCohortName.value || !selectedAnatomy.value || (isVirtual.value && sampleCount.value <= 0)) {
        messageStore.addError('Please fill all required fields.');
        return;
      }

      try {
        const cohortData = await girder.createCohort({
          name: newCohortName.value,
          anatomy: selectedAnatomy.value,
          isVirtual: isVirtual.value,
          samples: isVirtual.value ? sampleCount.value : undefined,
          showFittedDevice: showFittedDevice.value, // Pass the flag
        });

        if (cohortData.success) {
          girder.setLoadedCase(cohortData.data);
          girder.listCohorts();
          messageStore.addSuccess('Cohort created successfully!');

          await girder.submitCohortJob(cohortData.data);
          messageStore.addSuccess('Cohort job submitted successfully!');

          emit('created');
          emit('close');
        } else {
          if (cohortData.message) {
            messageStore.addError(cohortData.message);
          }
        }
      } catch (error) {
        messageStore.addError('Failed to create cohort. Please try again.');
      }
    };

    return {
      newCohortName,
      selectedAnatomy,
      isVirtual,
      sampleCount,
      showFittedDevice,
      anatomies,
      createCohort,
      handleDeviceChange,
    };
  },
};
</script>

<style scoped>
/* Add styles as necessary */
</style>