<template>
  <div class="d-flex flex-column" height="100%" style="overflow: hidden;">
    <!-- Action Buttons -->
    <v-row class="mt-4 pa-4" align="center">
      <v-col cols="4">
        <v-btn color="primary" @click="dialogNewCase = true" block>
          <v-icon left>mdi-plus-box</v-icon>
          Case
        </v-btn>
      </v-col>

      <v-col cols="4">
        <v-btn color="primary" @click="dialogLoadCase = true" block>
          <v-icon left>mdi-folder-open</v-icon>
          Cases
        </v-btn>
      </v-col>

      <v-col cols="4">
        <v-btn color="primary" @click="dialogReferences = true" block>
          References
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="pa-4" align="center">
      <v-col cols="4">
        <v-btn color="primary" @click="showCohortCreator = true" block>
          <v-icon left>mdi-plus-box</v-icon>
          Cohort
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn color="primary" @click="dialogCohortBrowser = true" block>
          <v-icon left>mdi-folder-multiple</v-icon>
          Cohorts
        </v-btn>
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <case-creation-dialog
      v-model="dialogNewCase"
      @created="handleCaseCreated"
    />

    <case-browser-dialog
      v-model="dialogLoadCase"
    />

    <v-dialog v-model="showCohortCreator" max-width="600px" persistent>
      <v-card>
        <cohort-creator @close="showCohortCreator = false" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReferences" scrollable max-width="60%">
      <v-card>
        <v-card-title>
          <span class="headline">References</span>
        </v-card-title>

        <v-card-text class="scrollable-content">
          <v-data-table :headers="header_reference_list" :items="referencesList" hide-default-footer>
            <template v-slot:item.anatomy="{ item }">
              {{ item.anatomy }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn color="primary" small @click="loadReference(item.id)">
                Load
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogReferences = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <cohort-browser
      :visible="dialogCohortBrowser"
      @close="dialogCohortBrowser = false"
    />

    <v-divider class="my-4"></v-divider>

    <!-- Case Details -->
    <case-details-card
      v-if="loadedCase"
      :loaded-case="loadedCase"
      :job-details="computedJobDetails"
      @show-log="showLogDialog = true"
    />

    <!-- Log Dialog -->
    <v-dialog v-model="showLogDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Job Log</span>
        </v-card-title>
        <v-card-text>
          <pre style="max-height: 60vh; overflow-y: auto; white-space: pre-wrap;">
            <code>{{ jobLog }}</code>
          </pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showLogDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { computed, ref, onMounted, watch, onBeforeUnmount } from 'vue';
import { useGirderStore } from '../store/girder-store';
import { SingleCase } from '../store/girder-store';
import { useDatasetStore } from '../store/datasets';
import { useFileStore } from '../store/datasets-files';
import { useModelStore } from '../store/datasets-models';
import { useSurfaceMeasurementStore } from '../store/tools/surfaceMeasurements'
import { useLandmarkStore } from '../store/tools/landmarks';
import { useSurface3DAnnotationStore } from '../store/tools/surfaceDrawings';
import { useMessageStore } from '../store/messages';
import { progressCallback, uploadProgress, resetProgress } from '../store/progress-store';
import GirderLogin from './GirderLogin.vue';
import CohortCreator from './CohortCreator.vue';
import CohortBrowser from './CohortBrowser.vue';
import { useLabelmapStore } from '../store/datasets-labelmaps';
import { vtiWriter } from '../io/vtk/async';
import { debounce } from 'lodash';
import CaseCreationDialog from './girder/CaseCreationDialog.vue';
import CaseBrowserDialog from './girder/CaseBrowserDialog.vue';
import CaseDetailsCard from './girder/CaseDetailsCard.vue';
import { storeToRefs } from 'pinia';

export default {
  name: 'GirderBrowser',
  components: {
    CohortCreator,
    CohortBrowser,
    CaseCreationDialog,
    CaseBrowserDialog,
    CaseDetailsCard,
  },

  setup() {
    const girderStore = useGirderStore();
    const { loadedCase, cases, jobDetails } = storeToRefs(girderStore);
    const newProjectName = ref('');
    const selectedAnatomy = ref('');
    const selectedTask = ref('');
    const dialogNewCase = ref(false);
    const searchQuery = ref('');
    const dialogLoadCase = ref(false);
    const showCohortCreator = ref(false);
    const fileStore = useFileStore();
    const taskFilter = ref('');
    const stateFilter = ref('');
    const anatomyFilter = ref('');
    const datasetStore = useDatasetStore();
    const expandedCase = ref([]);
    const dialogCohortBrowser = ref(false);
    const refreshSuccess = ref(false);
    const loadedJobDetails = ref({});
    const surfaceStore = useSurfaceMeasurementStore();
    const states = ref(['state_empty', 'state_image_uploaded', 'state_image_processed']);
    const tasks = ref(['Auto measurements', 'Prepare for simulation', 'Reconstruct', 'Fit device', 'Registration', 'Foot Fitting']);
    const header_reference_list = ref([{ title: 'Anatomy', sortable: true, value: 'anatomy' }, { title: 'Type', sortable: true, value: 'type' }, { title: 'Action', sortable: true, value: 'id' }]);
    const currentJobState = ref<number | null>(null);
    const dialogReferences = ref(false);
    const showLogDialog = ref(false);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const visibleCaseIds = ref(new Set());
    const isLoadingAll = ref(false);
    const isSearching = ref(false);

    // Mock jobDetails when not available
    const defaultJobDetails = {
      jobId: 'N/A',
      progress: { message: 'No progress available' },
      log: [],
    };

    // Function to refresh job details and log
    const refreshJobDetails = async () => {
      if (loadedCase.value?.type === 'Reference') {
        jobDetails.value = defaultJobDetails;
        return;
      }

      if (!loadedCase.value?.meta?.jobId) {
        jobDetails.value = { 
          log: [], 
          progress: { message: 'No Job Available' }, 
          jobId: 'N/A', 
          status: '' 
        };
        return;
      }

      try {
        const job = await girderStore.getJob(loadedCase.value.meta.jobId);
        if (job && job.jobId) {
          jobDetails.value = job;
          // Also update the job details in the store
          girderStore.jobDetails[loadedCase.value.meta.jobId] = job;
        } else {
          jobDetails.value = defaultJobDetails;
        }
      } catch (error) {
        console.error('Error fetching job details:', error);
        jobDetails.value = defaultJobDetails;
      }
    };

    // Function to check if the job state is finished
    const isJobFinished = (state: string) => {
      return state === 'state_image_processed'; // Assuming this is the finished state
    };

    // Set up an interval to refresh job details periodically when there's an active job
    const jobRefreshInterval = ref(null);

    watch(loadedCase, async (newCase) => {
      if (newCase) {
        await refreshJobDetails();
        
        // Clear any existing interval
        if (jobRefreshInterval.value) {
          clearInterval(jobRefreshInterval.value);
        }
        
        // If there's a job and it's not finished, set up periodic refresh
        if (newCase.meta?.jobId && !isJobFinished(newCase.state)) {
          jobRefreshInterval.value = setInterval(refreshJobDetails, 5000); // Refresh every 5 seconds
        }
      } else {
        // Clear interval when no case is loaded
        if (jobRefreshInterval.value) {
          clearInterval(jobRefreshInterval.value);
        }
        jobDetails.value = defaultJobDetails;
      }
    });

    // Clean up interval on component unmount
    onBeforeUnmount(() => {
      if (jobRefreshInterval.value) {
        clearInterval(jobRefreshInterval.value);
      }
    });

    const refreshCaseFiles = async () => {
      const newJobState = jobDetails.value.status; // Assuming `status` holds the job state

      if (currentJobState.value && loadedCase.value) {
        // If the job state is finished (3), trigger the download of case files
        if (newJobState === 3 && currentJobState.value !== 3) {
          useMessageStore().addSuccess('Job completed. Downloading updated case data...');
          await girderStore.downloadCase(loadedCase.value?.id, progressCallback, true);
        }
      }

      // Update the current job state after checking
      currentJobState.value = newJobState;

    };

    // Computed job details to avoid null values
    const computedJobDetails = computed(() => {
      if (!loadedCase.value?.meta?.jobId) return null;
      return jobDetails.value[loadedCase.value.meta.jobId] || null;
    });

    // Computed job log to avoid null
    const jobLog = computed(() => {
      return computedJobDetails.value.log.length
        ? computedJobDetails.value.log.join('\n')
        : 'No log available';
    });

    // Computed flag to check if job details are available
    const jobIsAvailable = computed(() => {
      return computedJobDetails.value.jobId !== 'N/A';
    });

    let refreshInterval: any = null;
    let caseRefreshInterval: any = null;

    // Function to handle table updates (pagination, sorting, etc)
    const handleTableUpdate = async (options) => {
      currentPage.value = options.page;
      itemsPerPage.value = options.itemsPerPage;
      
      // Calculate offset based on current page
      const offset = (currentPage.value - 1) * itemsPerPage.value;
      await girderStore.listCases(offset, itemsPerPage.value);
    };

    // Function to handle search and filters
    const handleSearch = debounce(async () => {
      if (!searchQuery.value && !taskFilter.value && !stateFilter.value && !anatomyFilter.value) {
        // If no filters, just load current page
        await handleTableUpdate({ page: currentPage.value, itemsPerPage: itemsPerPage.value });
        return;
      }

      // If we have filters, we need all cases
      isSearching.value = true;
      try {
        await girderStore.listAllCases();
      } finally {
        isSearching.value = false;
      }
    }, 300);

    // Watch for filter changes
    watch([searchQuery, taskFilter, stateFilter, anatomyFilter], handleSearch);

    // Watch for dialog visibility to start/stop case list refresh
    watch(dialogLoadCase, async (isVisible) => {
      // Clear any existing interval first
      if (refreshInterval) {
        clearInterval(refreshInterval);
        refreshInterval = null;
      }

      if (isVisible) {
        console.log('Dialog opened, loading initial cases...');
        try {
          // Initial load of first page
          await handleTableUpdate({ page: 1, itemsPerPage: itemsPerPage.value });
          
          // Start background refresh for visible cases
          refreshInterval = setInterval(async () => {
            if (visibleCaseIds.value.size > 0) {
              await handleTableUpdate({
                page: currentPage.value,
                itemsPerPage: itemsPerPage.value
              });
            }
          }, 10000);
        } catch (error) {
          console.error('Error loading cases:', error);
        }
      } else {
        console.log('Dialog closed, stopping case refresh');
        visibleCaseIds.value.clear();
      }
    });

    // Watch for loaded case to handle job status updates
    watch(loadedCase, (newCase) => {
      // Clear existing interval if any
      if (caseRefreshInterval) {
        clearInterval(caseRefreshInterval);
        caseRefreshInterval = null;
      }

      if (newCase?.meta?.jobId && !isJobFinished(newCase.state)) {
        // Initial refresh
        refreshJobDetails();
        
        // Set up interval for unfinished jobs only
        caseRefreshInterval = setInterval(async () => {
          await refreshJobDetails();
          await refreshCaseFiles();
        }, 5000);
      }
    });

    // Clean up intervals when component is unmounted
    onBeforeUnmount(() => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
        refreshInterval = null;
      }
      if (caseRefreshInterval) {
        clearInterval(caseRefreshInterval);
        caseRefreshInterval = null;
      }
      visibleCaseIds.value.clear();
    });

    // Update the onMounted function to remove automatic refresh
    onMounted(async () => {
      console.log('GirderBrowser mounted');
      await girderStore.initializeAxios();
      await girderStore.login(girderStore.key);
      await girderStore.listProjects(); // This will populate references and thus anatomies
    });

    // Computed property to check if the server is connected
    const isServerConnected = computed(() => girderStore.connected);

    watch(
      () => [girderStore.host, girderStore.port, girderStore.key],
      () => {
        girderStore.initializeAxios();
        girderStore.login(girderStore.key);
      },
      { deep: true }
    );

    const selectedImageID = computed(() => {
      const { primarySelection } = useDatasetStore();
      if (primarySelection?.type === 'image') {
        return primarySelection.dataID;
      }
      if (primarySelection?.type === 'dicom') {
        return primarySelection.volumeKey || null;
      }
      return null;
    });

    const selectedMeshID = computed(() => {
      const { currentId } = useModelStore();
      return currentId;
    });

    const isImageLoaded = computed(() => selectedImageID.value !== null);

    const isModelLoaded = computed(() => selectedMeshID.value !== null);

    const validFileLoaded = computed(() => isImageLoaded.value || isModelLoaded.value);

    const canCorrect = computed(() => {
      const correctableTasks = [
        'Auto measurements', 'Registration', 'Correct'
      ];
      return correctableTasks.includes(loadedCase.value?.meta?.task);
    });

    const updateData = async (d: string) => {
      progressCallback(0);

      const caseId = loadedCase.value.id;
      const fileStore = useFileStore();

      try {
        // Handle models
        if (!loadedCase.value) {
          throw new Error('No case loaded');
        }

        // Upload new files with metadata
        const allUploadedItems = [];
        const allFiles = [];

        if (isImageLoaded.value) {
          const id = selectedImageID.value;
          if (!id) {
            throw new Error('No image ID found');
          }
          const file = fileStore.getFiles(id);
          const itemIDs = await girderStore.uploadFile(file, caseId, 'Update', progressCallback);
          allFiles.push({
            _id: id,
            data: [{ dataID: id, dataType: 'image' }],
            ok: true,
          });
          allUploadedItems.push(itemIDs[0]);
        }

        if (isModelLoaded.value) {
          const id = selectedMeshID.value;
          if (!id) {
            throw new Error('No mesh ID found');
          }
          const file = fileStore.getFiles(id);
          const modelUploadResult = await girderStore.uploadFile(file, caseId, 'Update', progressCallback);
          allFiles.push({
            _id: id,
            data: [{ dataID: id, dataType: 'model' }],
            ok: true,
          });
          allUploadedItems.push(...modelUploadResult);
        }

        progressCallback(50);

        // Store paths and create job
        await girderStore.storeGirderPaths(
          allFiles.map((file) => ({ ok: true, data: file.data })),
          allUploadedItems.map((item) => ({ _id: item })),
        );

        // await girderStore.createJobWithInputItems(allUploadedItems, 'Update', 'Update', caseId);

        progressCallback(80);

        // Refresh case list and current case
        await girderStore.listCases();
        girderStore.setLoadedCase(girderStore.cases[caseId]);

        progressCallback(100);
      } catch (error) {
        console.error('Error in updateData:', error);
        throw error;
      } finally {
        progressCallback(0);
      }
    };

    const uploadData = async (caseId, task) => {
      try {
        await girderStore.uploadData(caseId, task, progressCallback);
      } catch (error) {
        console.error(`Error during upload for case ${caseId}:`, error);
      }
    };

    const correctData = async (d) => {
      try {
        await girderStore.correctData(loadedCase.value.id, progressCallback);
      } catch (error) {
        console.error('Error in correctData:', error);
        throw error;
      }
    };

    const uploadReferenceAnnotations = async (girderPath) => {
      try {
        console.log("Upload initiated.");
        const measurementStore = useSurfaceMeasurementStore();
        const { measurements } = measurementStore.serializeMeasurements();
        const metadata = { measurements };
        await girderStore.updateMetadata(girderPath, metadata);
        useMessageStore().addSuccess("Successfully stored annotations");
      } catch (error) {
        console.error("Error uploading reference annotations:", error);
      }
    };

    const downloadCase = async (caseId) => {
      surfaceStore.deleteAllMeasurements();
      datasetStore.deleteAllData();
      girderStore.resetPaths();
      girderStore.setLoadedCaseByID(caseId);
      refreshJobDetails();
      await girderStore.downloadCase(caseId, progressCallback);
    };

    const loadReference = async (referenceId) => {
      try {
        // Clear existing data
        surfaceStore.deleteAllMeasurements();
        datasetStore.deleteAllData(); 
        girderStore.resetPaths();

        // Load the reference item
        await girderStore.loadItem(referenceId);

        // Find and set the reference case
        const referenceCase = Object.values(girderStore.references).find(s => s.id === referenceId);
        if (referenceCase) {
          girderStore.setLoadedCase(referenceCase);
        } else {
          console.warn('Reference case not found in list');
        }

        // Close dialog
        dialogReferences.value = false;

      } catch (error) {
        console.error('Failed to load reference:', error);
        throw error; // Re-throw to allow error handling up the chain
      }
    };

    const formatProgressMessage = (message) => {
      if (!message) return 'No progress available';
      const firstLine = message.split('\n')[0];
      return firstLine.length > 100 ? firstLine.substring(0, 100) + '...' : firstLine;
    };

    const handleCaseCreated = (caseData) => {
      girderStore.setLoadedCase(caseData);
      dialogNewCase.value = false;
    };

    // Computed properties from store
    const anatomies = computed(() => girderStore.anatomies);
    const casesList = computed(() => girderStore.cases);
    const referencesList = computed(() => {
      return Object.values(girderStore.references).map(ref => ({
        anatomy: ref.anatomy,
        id: ref.id
      }));
    });

    // Add back the currentJobDetails computed property
    const currentJobDetails = computed(() => {
      const jobId = loadedCase.value?.meta?.jobId;
      if (!jobId) {
        return null;
      }
      return jobDetails.value[jobId];
    });

    const showJobDetails = computed(() => {
      return !!currentJobDetails.value;
    });

    const filteredCasesList = computed(() => {
      return Object.values(casesList.value).filter((caseItem) => {
        const matchesTask = taskFilter.value ? caseItem.meta?.task === taskFilter.value : true;
        const matchesState = stateFilter.value ? caseItem.state === stateFilter.value : true;
        const matchesAnatomy = anatomyFilter.value ? caseItem.meta?.anatomy === anatomyFilter.value : true;
        const searchQ = searchQuery.value ? searchQuery.value.toLowerCase() : '';
        const matchesSearch = caseItem.name.toLowerCase().includes(searchQ);
        return matchesTask && matchesState && matchesAnatomy && matchesSearch;
      }).map((caseItem) => ({
        name: caseItem.name,
        anatomy: caseItem.meta?.anatomy,
        task: caseItem.meta?.task,
        state: caseItem.state,
        created: caseItem.created,
        lastUpdated: caseItem.meta?.lastUpdated,
        jobStatus: caseItem.meta?.jobStatus,
        action: caseItem.id
      }));
    });

    const getStateProgress = (item) => {
      if (item.jobStatus !== undefined) {
        const status = parseInt(item.jobStatus);
        switch (status) {
          case 3: return 100;  // Success
          case 4: return 100;  // Error
          case 5: return 100;  // Canceled
          case 2: return 50;   // Running
          case 1: return 25;   // Queued
          case 0: return 0;    // Inactive
          default: return 0;
        }
      }
      if (item.state === 'state_image_processed') return 100;
      if (item.state === 'state_image_uploaded') return 50;
      return 0;
    };

    const getStateColor = (item) => {
      if (item.jobStatus !== undefined) {
        const status = parseInt(item.jobStatus);
        switch (status) {
          case 3: return 'success';
          case 4: return 'error';
          case 5: return 'warning';
          case 2: return 'info';
          case 1: return 'primary';
          case 0: return 'grey';
        }
      }
      return 'blue-grey';
    };

    const createCase = async () => {
      if (!newProjectName.value || !selectedAnatomy.value || !selectedTask.value) {
        alert('Please fill all required fields.');
        return;
      }
      try {
        const caseData = await girderStore.createCase({
          name: newProjectName.value,
          anatomy: selectedAnatomy.value,
          anatomicalSide: 'Left',
          task: selectedTask.value,
        });
        girderStore.setLoadedCase(caseData);
      } catch (error) {
        console.error('Failed to create case:', error);
      }
    };

    return {
      newCaseName: newProjectName,
      anatomies,
      selectedAnatomy,
      selectedTask,
      isJobFinished,
      jobDetails,
      currentCase: loadedCase,
      dialogNewCase,
      dialogLoadCase,
      searchQuery,
      loadedCase,
      isImageLoaded,
      expandedCase,
      header_reference_list,
      isServerConnected,
      computedJobDetails,
      jobLog,
      dialogCohortBrowser,
      jobIsAvailable,
      uploadData,
      states,
      tasks,
      taskFilter,
      stateFilter,
      dialogReferences,
      referencesList,
      loadReference,
      showCohortCreator,
      uploadReferenceAnnotations,
      isModelLoaded,
      selectedMeshID,
      updateData,
      correctData,
      validFileLoaded,
      downloadCase,
      refreshSuccess,
      casesList,
      filteredCasesList,
      anatomyFilter,
      uploadProgress,
      createCase,
      listCases: girderStore.listCases,
      getStateProgress,
      getStateColor,
      showLogDialog,
      formatProgressMessage,
      canCorrect,
      itemsPerPage,
      handleTableUpdate,
      isSearching,
      handleCaseCreated,
      currentJobDetails,
      showJobDetails,
    };
  }
}
</script>

<style scoped>
#data-module {
  display: flex;
  flex-flow: column;
}

#data-panels {
  flex: 2;
  overflow-y: auto;
}

.collection-header-icon {
  flex: 0;
  margin-right: 16px;
}

/* Add styling for the fixed header */
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.patient-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  /* 24px accomodates the open/close icon indicator */
  max-width: calc(100% - 24px);
}

.patient-header-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty-state {
  display: none;
}

.no-panels:empty+.empty-state {
  display: block;
}

:deep(.v-progress-circular) {
  margin: 0 8px;
}

:deep(.v-progress-circular--indeterminate) {
  animation: progress-circular-rotate 1s linear infinite;
}

:deep(.v-progress-circular__overlay) {
  stroke-width: 4px !important;
}

:deep(.v-data-table-progress .v-progress-linear) {
  background-color: rgb(var(--v-theme-warning)) !important;
  height: 4px !important;
}

.scrollable-content {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
}
</style>
