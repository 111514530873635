<script lang="ts">
import { defineComponent, onBeforeUnmount, toRefs } from 'vue';
import { usePickerStore } from '@/src/store/pickerStore';
import { useCrosshairsToolStore } from '@/src/store/tools/crosshairs';
import { useToolStore } from '@/src/store/tools';
import { Tools } from '@/src/store/tools/types';
import { watch } from 'vue';
import type vtkPointPicker from '@kitware/vtk.js/Rendering/Core/PointPicker';
import type { Vector3 } from '@kitware/vtk.js/types';

interface PickerSelection {
  getProperties: () => {
    worldPosition: Float64Array | Vector3;
  };
}

export default defineComponent({
  name: 'Crosshairs3D',
  props: {
    viewId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { viewId } = toRefs(props);
    const crosshairsStore = useCrosshairsToolStore();
    const pickerStore = usePickerStore();
    const toolStore = useToolStore();

    // Setup picker from PickerStore
    pickerStore.setupPicker(viewId.value);

    // Store cleanup functions for event listeners
    const cleanupListeners: (() => void)[] = [];

    // Watch crosshairs tool state
    watch(
      () => toolStore.currentTool === Tools.Crosshairs,
      (isCrosshairsActive) => {
        // Clean up existing listeners
        cleanupListeners.forEach(cleanup => cleanup());
        cleanupListeners.length = 0;

        if (isCrosshairsActive) {
          const widgetState = crosshairsStore.getWidgetFactory().getWidgetState();

          // Add pointer move listener using picker store
          const handleMouseMove = (callData: { position: { x: number; y: number } }) => {
            if (widgetState.getPlaced()) return;

            const pos = callData.position;
            const x = pos.x;
            const y = pos.y;

            (pickerStore.selector as vtkPointPicker)
              ?.selectAsync(pickerStore.renderer, x, y, x, y)
              .then((selection: PickerSelection[]) => {
                if (selection.length > 0) {
                  const { worldPosition } = selection[0].getProperties();
                  if (worldPosition) {
                    // Convert Float64Array to Vector3 before passing to store
                    const positionArray: Vector3 = [
                      worldPosition[0],
                      worldPosition[1],
                      worldPosition[2]
                    ];
                    crosshairsStore.setPosition(positionArray);
                    pickerStore.viewProxy?.getRenderWindow().render();
                  }
                }
              });
          };

          // Add left click listener to toggle placement
          const handleLeftClick = (callData: { position: { x: number; y: number } }) => {
            const pos = callData.position;
            const x = pos.x;
            const y = pos.y;

            (pickerStore.selector as vtkPointPicker)
              ?.selectAsync(pickerStore.renderer, x, y, x, y)
              .then((selection: PickerSelection[]) => {
                if (selection.length > 0) {
                  const { worldPosition } = selection[0].getProperties();
                  if (worldPosition) {
                    // Convert Float64Array to Vector3 before passing to store
                    const positionArray: Vector3 = [
                      worldPosition[0],
                      worldPosition[1],
                      worldPosition[2]
                    ];
                    crosshairsStore.setPosition(positionArray);
                    // Toggle placement state
                    widgetState.setPlaced(!widgetState.getPlaced());
                    pickerStore.viewProxy?.getRenderWindow().render();
                  }
                }
              });
          };

          const mouseMoveSubscription = pickerStore.viewProxy
            ?.getInteractor()
            .onMouseMove(handleMouseMove);

          const leftClickSubscription = pickerStore.viewProxy
            ?.getInteractor()
            .onLeftButtonPress(handleLeftClick);

          if (mouseMoveSubscription) {
            cleanupListeners.push(() => mouseMoveSubscription.unsubscribe());
          }
          if (leftClickSubscription) {
            cleanupListeners.push(() => leftClickSubscription.unsubscribe());
          }
        }
      },
      { immediate: true }
    );

    // Clean up on component unmount
    onBeforeUnmount(() => {
      cleanupListeners.forEach(cleanup => cleanup());
    });

    return {};
  },
});
</script>

<template>
  <div />
</template> 