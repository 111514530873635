import { defineStore } from 'pinia';
import axios, { AxiosInstance } from 'axios';
import cookies from 'js-cookie';
import {
  importDataSources,
  convertSuccessResultToDataSelection,
} from '@/src/io/import/importDataSources';
import { useDatasetStore } from '@/src/store/datasets';
import { fileToDataSource } from '../io/import/dataSource';
import { useLandmarkStore } from '@/src/store/tools/landmarks'; // Adjust the path as needed
import { useLineStore } from './tools/lines';
import { useAngleStore } from './tools/angles';
import { useModelStore } from './datasets-models';
import { useSplineStore } from './tools/splines';
import { useSurfaceMeasurementStore } from './tools/surfaceMeasurements';
import { ProjectType } from './tools/types'
import { useGroupStore } from './datasets-groups';
import { useSurface3DAnnotationStore } from './tools/surfaceDrawings';
import { useFileStore } from './datasets-files';
import { useLabelmapStore } from './datasets-labelmaps';

interface GirderConfig {
  version: string;
  timeout: number;
}

interface Project {
  name: string;
  id: string;
  type: ProjectType;
  update: boolean,
}

interface Reference extends Project {
  type : ProjectType;
  anatomy: string;
}

interface Cohort extends Project {
  type : ProjectType;
  anatomy: string;
  items: string[];
  realVsVirtual: string;
  prepared: boolean;
}

export interface SingleCase extends Project {
  meta: {
    input_items: string[];
    jobId: string;
    output_items: string[];
    task: string;
    anatomy: string;
    jobStatus: string;
  };
  state: string;
  type: ProjectType;
  created: string;
  lastUpdated?: string;  // Optional field for job's last update time
}

interface Progress {
  current: number;
  message: string;
  notificationId: string;
}

interface Job {
  jobId: string;
  log: string;
  progress : Progress;
  status: string;
}

interface FileGirderPath {
  id: string;
  path: string;
  type: string;
}

// Define the store
export const useGirderStore = defineStore('girder', {
  state: () => ({
    config: {
      version: 'v1',
      timeout: 120000,
    } as GirderConfig,
    host: 'https://v2202410237021291825.nicesrv.de',
    port: '',
    key: "",
    connected: false,
    rest: null as AxiosInstance | null,
    cases: {} as { [id: string]: Project },
    references: {} as { [id: string]: Reference },
    caseFolder: null as { _id: string; name: string } | null,
    referenceFolder: null as { _id: string; name: string } | null,
    cohortsFolder: null as { _id: string; name: string } | null,
    cohorts: {} as { [id: string]: Cohort },
    collection: null as { _id: string; name: string } | null,
    loadedCase: null as Project | null,
    ALLOWED_STATES: ['state_empty', 'state_image_uploaded', 'state_image_processed'],
    inputItem: null,
    outputItem: null,
    girderPaths: [] as FileGirderPath[],
    datasetStore: useDatasetStore(),
    jobDetails: {} as { [id: string]: Job },
  }),
  getters: {
    anatomies: (state) => {
      // Extract unique anatomies from references
      const anatomySet = new Set<string>();
      Object.values(state.references).forEach(ref => {
        if (ref.anatomy) {
          anatomySet.add(ref.anatomy);
        }
      });
      return Array.from(anatomySet)
        .sort()
        .map(anatomy => ({
          text: anatomy,
          value: anatomy
        }));
    }
  },
  actions: {
    initializeAxios() {
      this.rest = axios.create({
        baseURL: `${this.host}${this.port ? `:${this.port}` : ''}/api/v1`,
        timeout: this.config.timeout,
      });
      this.addRequestDefaultTokenFromCookies();
    },

    addRequestDefaultTokenFromCookies() {
      if (!this.rest) return;
      const token = cookies.get('girderToken');
      if (token) {
        this.rest.defaults.headers.common['Girder-Token'] = token;
      }
    },

    async login(apiKey: string, duration: number = 5) {
      if(apiKey === '') {
        this.connected = false;
        return;
      }

      try {
        if (!this.rest) {
          this.initializeAxios();
        }

        const { data } = await this.rest.post('/api_key/token', null, {
          params: {
            key: apiKey,
            duration
          }
        });

        // Save token in cookies
        cookies.set('girderToken', data.authToken.token, { sameSite: 'strict' });
        this.addRequestDefaultTokenFromCookies();
        this.connected = true;

        // Only proceed with initialization if we're connected
        if (this.connected) {
          await this.searchCollection();
          await this.findProjectFolders();
          await this.listProjects(); // This will populate references and thus anatomies
        }
      } catch (error) {
        this.connected = false;
        console.error('Login with API key failed:', error);
      }
    },

    async searchCollection(retryCount = 3, retryDelay = 1000) {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return false;
      }
      for (let attempt = 1; attempt <= retryCount; attempt++) {
        try {
          const response = await this.rest.get('/collection', {
            params: {
              text: 'shapemeans_suite',
              limit: 2,
              offset: 0,
              sort: 'name',
              sortdir: 1,
            },
          });

          // Check if folder with name "cases" exists and store it
          const collection = response.data.find(col => col.name === 'shapemeans_suite');
          if (collection) {
            this.collection = { _id: collection._id, name: collection.name };
            return true;
          } else {
            console.warn(`No collection found (attempt ${attempt}/${retryCount})`);
            if (attempt < retryCount) {
              await new Promise(resolve => setTimeout(resolve, retryDelay));
            }
          }
        } catch (error) {
          console.error(`Failed to search for collection shapemeans_suite (attempt ${attempt}/${retryCount})`, error);
          if (attempt < retryCount) {
            await new Promise(resolve => setTimeout(resolve, retryDelay));
          }
        }
      }
      return false;
    },

    async uploadData(caseId: string, task: string, progressCallback: (progress: number) => void = () => {}) {

      console.log("uploadData", caseId, task);

      if (!this.connected || !this.rest) {
        throw new Error('Not connected to server');
      }

      const allUploadedItems: string[] = [];
      const allFiles: { _id: string, data: { dataID: string, dataType: string }[], ok: boolean }[] = [];

      try {
        // Get primary image selection
        const { primarySelection } = this.datasetStore;
        const fileStore = useFileStore();
        const modelStore = useModelStore();

        if (primarySelection?.type === 'image' || primarySelection?.type === 'dicom') {
          const id = primarySelection.type === 'image' ? primarySelection.dataID : primarySelection.volumeKey;
          if (id) {
            const file = fileStore.getFiles(id);
            const itemIDs = await this.uploadFile(file, caseId, task, progressCallback);
            
            allFiles.push({
              _id: id,
              data: [{ dataID: id, dataType: primarySelection.type }],
              ok: true
            });

            allUploadedItems.push(itemIDs[0]);
          }
        }

        // Get current model
        const modelId = modelStore.currentId;
        if (modelId) {
          const file = fileStore.getFiles(modelId);
          const modelUploadResult = await this.uploadFile(file, caseId, task, progressCallback);

          allFiles.push({
            _id: modelId,
            data: [{ dataID: modelId, dataType: 'model' }],
            ok: true
          });

          allUploadedItems.push(...modelUploadResult);

          // Handle landmarks and surface labels if model was uploaded
          if (modelUploadResult.length > 0) {
            const lmStore = useLandmarkStore();
            const paintStore = useSurface3DAnnotationStore();
            
            await this.updateMetadata(modelUploadResult[0], {
              landmarks: lmStore.serializeJSON(modelId),
              surfaceLabels: paintStore.serializeJSON(modelId)
            });
          }
        }

        // Store paths and create job
        await this.storeGirderPaths(
          allFiles.map(file => ({ ok: true, data: file.data })),
          allUploadedItems.map(item => ({ _id: item }))
        );

        await this.createJobWithInputItems(allUploadedItems, task, task, caseId);
        await this.listCases();
        this.setLoadedCaseByID(caseId);

      } catch (error) {
        console.error('Upload failed:', error);
        throw error;
      } finally {
        progressCallback(0);
      }
    },

    async findProjectFolders() {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return false;
      }
      try {
        if (!this.collection) {
          console.warn('Cannot search for folders: collection not found');
          return false;
        }
        
        this.caseFolder = await this.searchFolderInCollection("cases", this.collection._id);
        this.referenceFolder = await this.searchFolderInCollection("references", this.collection._id);
        this.cohortsFolder = await this.searchFolderInCollection("cohorts", this.collection._id);
        
        return this.caseFolder !== null;
      } catch (error) {
        console.error('Failed to search for folders:', error);
        return false;
      }
    },

    async searchFolderInCollection(name: string, collection: string) {
      try {
        const response = await this.rest.get('/folder', {
          params: {
            parentType: 'collection',
            parentId: collection,
            name: name,
            limit: 2,
            offset: 0,
            sort: 'lowerName',
            sortdir: 1,
          },
        });

        // Check if folder with name "cases" exists and store it
        const foundFolder = response.data.find(folder => folder.name === name);
        if (foundFolder) {
          return { _id: foundFolder._id, name: foundFolder.name };
        } else {
          console.warn("No 'references' folder found.");
          return null;
        }

      } catch (error) {
        console.error('Failed to search for "references" folder:', error);
        return null;
      }
    },

    setServerById(serverId: string | null = null) {
      if(serverId == 'dev'){
        this.host = 'https://g2.v2202410237021291825.nicesrv.de';
        this.port = '';
        this.key = 'vA6Ge83PlZVxe7ij6frE5NAzZq1btzPANxa4XbUW';
        this.initializeAxios();
        this.login(this.key,5);
      } else if(serverId == 'talus') {
        this.host = 'http://192.168.50.251';
        this.port = '8080';
        this.key = 'TLhwwqklz0MqH1hVvdbpGQYEelUMzbcdo28kiiX5';
        this.initializeAxios();
        this.login(this.key,5);
      } else {
        this.host = `https://${serverId}.v2202410237021291825.nicesrv.de`;
      }
    },

    setLoadedCaseByID(caseId: string) {
      this.loadedCase = this.cases[caseId] || null;
    },

    setLoadedCase(c: Project) {
      console.log("setLoadedCase", c);
      this.loadedCase = c;
    },

    async updateCaseState(caseId: string, newState: string) {

      if (!this.ALLOWED_STATES.includes(newState)) {
        console.error(`Invalid state. Allowed states are ${this.ALLOWED_STATES.join(", ")}`);
        return;
      }

      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      };

      try {
        const metadata = { state: newState };
        const { data } = await this.rest.put(`/folder/${caseId}/metadata`, metadata, config);
        // You might also want to update the local state here
      } catch (error) {
        console.error('Failed to update case state:', error);
      }
    },

    async submitCohortJob(cohort: Cohort) {
      try {
        // Create a job for the cohort using the cohort details
        const config = {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
          params: {
            title: `Create Cohort Job for ${cohort.name}`,
            type: 'Create cohort',
            public: false,
            kwargs: JSON.stringify({
              caseId: cohort.id,
              outputFolder: cohort.id,
              baseFolder: this.caseFolder?._id,
            }),
          },
        };
    
        // Step 1: Create the cohort job via API
        const { data: createdJob } = await this.rest.post('/job', null, config);

        console.log(createdJob);
    
        // Step 2: Update the job to set its status to QUEUED
        const updateConfig = {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
          params: {
            status: '1',
            progressTotal: 0,
            progressCurrent: 0,
            progressMessage: 'Cohort Job queued',
            notify: true,
          },
        };
    
        const { data: updatedJob } = await this.rest.put(`/job/${createdJob._id}`, null, updateConfig);
    
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        };
        const metadata = {
          state: this.ALLOWED_STATES[1],
          jobId: createdJob._id,
        };
    
        await this.rest.put(`/folder/${cohort.id}/metadata`, metadata, metadataConfig);
    
        return updatedJob;
      } catch (error) {
        console.error('Failed to create cohort job:', error);
        throw error;
      }
    },    

    async createJob(caseId: string, task: string) {
        try {
          const config = {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            params: {
              title: `Job for Case ${caseId}`,
              type: task,
              public: false,
              kwargs: JSON.stringify({
                caseId: caseId,
                outputFolder: caseId
              }),
            },
          };

          const { data: createdJob } = await this.rest.post('/job', null, config);


          // Step 2: Update the job to set status to QUEUED
          const updateConfig = {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            params: {
               status: '1', // Assuming '1' represents QUEUED status
               progressTotal: 0,
               progressCurrent: 0,
               progressMessage: 'Job queued',
               notify: true
            },
          };

          const { data: updatedJob } = await this.rest.put(`/job/${createdJob._id}`, null, updateConfig);

          return updatedJob;
        } catch (error) {
          console.error('Failed to create job:', error);
          if (error.response) {
            const { data } = error.response;
            console.error('Error details (if available):', data);
          }
          throw error;
        }
    },

    async storeGirderPaths(loadResults: any[], allItems: any[]) {

      // Ensure that loadResults and allItems have the same length
      if (loadResults.length !== allItems.length) {
        console.error("Mismatch between loadResults and allItems length");
        return;
      }

      // Iterate over the results and items to store or update the mappings
      loadResults.forEach((result, index) => {
        if (result.ok && result.data.length > 0) {
          const dataID = result.data[0].dataID;
          const type = result.data[0].dataType;
          const path = allItems[index]._id;

          // Find the existing entry by the path
          const existingEntryIndex = this.girderPaths.findIndex(entry => entry.path === path);

          if (existingEntryIndex !== -1) {
            // If entry exists, update it
            this.girderPaths[existingEntryIndex] = { id: dataID, path, type };
          } else {
            // If entry doesn't exist, add a new one
            this.girderPaths.push({ id: dataID, path, type });
          }
        } else {
          console.warn(`Skipping invalid load result at index ${index}`);
        }
      });
    },

    async createDicomFolder(folderID: string): Promise<string> {
      try {
          const itemConfig = {
              headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          };
          const { data } = await this.rest.post(`/item?folderId=${folderID}&name=DICOM`, {}, itemConfig);
          return data._id;
      } catch (error) {
          console.error('Failed to create DICOM item:', error);
          throw error;
      }
    },

    async createItem(folderID: string, fileName: string): Promise<string> {
      try {
          const itemConfig = {
              params: { folderId: folderID, name: fileName },
              headers: { 'Content-Type': 'application/json' },
          };
          const { data } = await this.rest.post('/item', null, itemConfig);
          return data._id;
      } catch (error) {
          console.error(`Failed to create item for file ${fileName}:`, error);
          throw error;
      }
    },

    async initiateUpload(file: File, itemFolderID: string): Promise<string> {
      try {
          const initConfig = {
              params: {
                  parentType: 'item',
                  parentId: itemFolderID,
                  name: file.name,
                  size: file.size,
                  mimeType: file.type,
              },
          };
  
          const { data } = await this.rest.post('/file', null, initConfig);
          return data._id;
      } catch (error) {
          console.error(`Failed to initiate upload for file ${file.name}:`, error);
          throw error;
      }
  },

  async uploadFileInChunks(
    file: File,
    itemFolderID: string,
    totalUploadedRef: { value: number },
    totalSize: number,
    progressCallback: (progress: number) => void
) {
    let startByte = 0;

    const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB

    // Request initial upload ID
    const uploadId = await this.initiateUpload(file, itemFolderID);

    while (startByte < file.size) {
        const endByte = Math.min(startByte + CHUNK_SIZE, file.size);
        const chunk = file.slice(startByte, endByte);

        try {
            // Sending chunk as body with parameters in the query string
            const query = `uploadId=${uploadId}&offset=${startByte}`;
            const uploadConfig = {
                headers: { 'Content-Type': file.type }, // Ensure the content-type matches the file type
                data: chunk, // Chunk is sent as the request body
            };

            await this.rest.post(`/file/chunk?${query}`, chunk, uploadConfig);

            totalUploadedRef.value += chunk.size;
            startByte = endByte;

            // Update progress
            const progress = Math.round((totalUploadedRef.value * 100) / totalSize);
            progressCallback(progress);
        } catch (error) {
            console.error(`Failed to upload chunk for file ${file.name} at offset ${startByte}:`, error);
            throw error;
        }
    }
},

    async uploadFiles(
      files: File[],
      folderID: string,
      dicomFolderID: string,
      totalSize: number,
      progressCallback: (progress: number) => void
  ): Promise<string[]> {
      const inputItems: string[] = [];
      const totalUploadedRef = { value: 0 };
  
      for (const file of files) {
          const isDicomFile = file.name.endsWith('.dcm');
          const targetFolderID = isDicomFile ? dicomFolderID : folderID;
  
          let itemFolderID: string;
          if (!isDicomFile) {
              try {
                  itemFolderID = await this.createItem(folderID, file.name);
                  inputItems.push(itemFolderID);
              } catch {
                  continue; // Skip this file if the item creation failed
              }
          } else {
              itemFolderID = dicomFolderID;
          }
  
          try {
              await this.uploadFileInChunks(file, itemFolderID, totalUploadedRef, totalSize, progressCallback);
          } catch {
              continue; // Skip this file if the upload failed
          }
      }
  
      return inputItems;
    },

    async uploadFile(files: File[], folderID: string, task: string, progressCallback: (progress: number) => void) {
      let dicomFolderID = folderID;
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);
      const dicomFiles = files.filter((file) => file.name.endsWith('.dcm'));
  
      if (dicomFiles.length > 0) {
          try {
              dicomFolderID = await this.createDicomFolder(folderID);
          } catch {
              throw new Error('Failed to prepare DICOM folder.');
          }
      }
  
      const inputItems = await this.uploadFiles(files, folderID, dicomFolderID, totalSize, progressCallback);

      if (dicomFiles.length > 0) {
        inputItems.push(dicomFolderID);
      }
  
      this.updateCaseState(folderID, this.ALLOWED_STATES[1]);
  
      progressCallback(100); // Ensure progress is set to 100% at the end
  
      return inputItems;
   },
  
    async createJobWithInputItems(inputItems: string[], task: string, baseTask: string, folderID: string) {
      try {
        const jobData = await this.createJob(folderID, task);

        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata: any = {
          state: this.ALLOWED_STATES[1],
          task: baseTask,
          jobId: jobData._id
        };

        // Only include input_items in metadata if the array is not empty
        if (inputItems && inputItems.length > 0) {
          metadata.input_items = JSON.stringify(inputItems);
        }

        await this.rest.put(`/folder/${folderID}/metadata`, metadata, metadataConfig);
      } catch (error) {
        console.error('Failed to update case metadata:', error);
      }
    },

    async updateMetadata(itemId: string,metadata: {}) {
      try {
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };

        // Send the PUT request to update metadata for the item
        await this.rest.put(`/item/${itemId}/metadata`, metadata, metadataConfig);

      } catch (error) {
        console.error(`Failed to update metadata for item ${itemId}:`, error);
      }
    },

    async getJob(jobId: string) {
      try {
        const response = await this.rest.get(`/job/${jobId}`);

        if (response.status === 200) {
          const jobData = response.data;
          return {
            jobId: jobData._id,
            log: jobData.log,
            progress: jobData.progress,
            status: jobData.status,
            updated: jobData.updated
          };
        }

        return null;
      } catch (error) {
        console.error('Error fetching job:', jobId, error);
        throw error;
      }
    },

    async listProjects() {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return;
      }
      await this.listReferences();
      await this.listCases();
      await this.listCohorts();
    },

    async listReferences() {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return;
      }
      if (!this.referenceFolder) {
        console.warn('No reference folder set');
        return;
      }
    
      // Configure request headers and parameters
      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        params: {
          folderId: this.referenceFolder._id,
        },
      };
    
      try {
        // Perform the API call to get references within the folder
        const { data } = await this.rest.get('/item', config);
    
        // Update the references state with the fetched data
        this.references = data.reduce((acc: { [id: string]: Reference }, referenceData: any) => {
          const anatomy = referenceData.meta.anatomy || '';
    
          const referenceObj: Reference = {
            name: anatomy,
            id: referenceData._id,
            anatomy: anatomy,
            type: ProjectType.Reference,
            update: false,
          };
    
          // Add each reference to the accumulator object using _id as the key
          acc[referenceData._id] = referenceObj;
          return acc;
        }, {});
    
      } catch (error) {
        console.error('Failed to list references:', error);
      }
    },

    async listCohorts() {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return;
      }
      if (!this.cohortsFolder) {
        console.warn('No cohort folder set');
        return;
      }
    
      // Configure request headers and parameters
      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        params: {
          parentType: 'folder',
          parentId: this.cohortsFolder._id,
        },
      };
    
      try {
        // Perform the API call to get references within the folder
        const { data } = await this.rest.get('/folder', config);
    
        // Update the references state with the fetched data
        this.cohorts = data.reduce((acc: { [id: string]: Reference }, referenceData: any) => {
          const anatomy = referenceData.meta.anatomy || '';
          const realOrVirtual = referenceData.meta.type || '';
          const items = referenceData.meta.output_items ? JSON.parse(referenceData.meta.output_items) : [];
          const prepared = referenceData.meta.prepared === 'true';
    
          const cohort: Cohort = {
            name: referenceData.name,
            id: referenceData._id,
            anatomy: anatomy,
            realVsVirtual : realOrVirtual,
            type: ProjectType.Cohort,
            items: items,
            prepared: prepared,
            update: false,
          };
    
          // Add each reference to the accumulator object using _id as the key
          acc[referenceData._id] = cohort;
          return acc;
        }, {});
    
      } catch (error) {
        console.error('Failed to list references:', error);
      }
    },

    async listCases() {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return;
      }

      try {
        const response = await this.rest.get('/folder', {
          params: {
            parentId: this.caseFolder?._id,
            parentType: 'folder',
            limit: 10000,
            sort: 'created',
            sortdir: -1,
          },
        });

        const cases = {};
        for (const item of response.data) {
          cases[item._id] = {
            id: item._id,
            name: item.name,
            meta: item.meta || {},
            state: item.meta?.state || 'state_empty',
            created: item.created,
            lastUpdated: item.meta?.lastUpdated,
            type: ProjectType.Case,
          };
        }
        this.cases = cases;
      } catch (error) {
        console.error('Failed to list cases:', error);
      }
    },

    async updateCaseWithJobDetails(caseId: string) {
      const caseItem = this.cases[caseId];
      if (!caseItem?.meta?.jobId) {
        return;
      }

      try {
        const jobDetails = await this.getJobDetails(caseItem.meta.jobId);
        if (!jobDetails) {
          return;
        }

        // Update the case with job details
        this.cases[caseId] = {
          ...caseItem,
          meta: {
            ...caseItem.meta,
            jobStatus: jobDetails.status,
            jobProgress: jobDetails.progress,
            jobLog: jobDetails.log,
            jobUpdated: jobDetails.updated
          }
        };

        // Also store the full job details
        this.jobDetails[caseItem.meta.jobId] = jobDetails;

        // If this is the currently loaded case, update it too
        if (this.loadedCase?.id === caseId) {
          this.loadedCase = this.cases[caseId];
        }
      } catch (error) {
        console.error('Failed to update job details for case:', error);
      }
    },

    async getJobDetails(jobId: string) {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return null;
      }

      try {
        const response = await this.rest.get(`/job/${jobId}`);
        return response.data;
      } catch (error) {
        console.error('Failed to get job details:', error);
        return null;
      }
    },

    async loadAnnotation(loadResult: any, meta: any) {

      if (loadResult.data[0]?.dataType !== 'model') return;

      const landmarkStore = useLandmarkStore();
      const lineStore = useLineStore();
      const splineStore = useSplineStore();
      const angleStore = useAngleStore();
      const modelStore = useModelStore();
      const allMeasurements = useSurfaceMeasurementStore();
      const groupStore = useGroupStore();
    
      if (!loadResult.ok || loadResult.data.length === 0) return;
    
      const dataID = loadResult.data[0].dataID;
      const measurements = JSON.parse(meta.meta.measurements || '[]');

      // Create a group for the loaded model
      groupStore.createGroup(dataID);

      if (meta.meta.connectedDevice) {
        const connectedDevicePath = meta.meta.connectedDevice;
      
        const existingEntry = this.girderPaths.find(entry => entry.path === connectedDevicePath);
      
        if (existingEntry) {
          const connectedDeviceId = existingEntry.id;
          groupStore.addSubModelToGroup(dataID,connectedDeviceId);
        } else {
          console.warn(`No matching entry found in girderPaths for path: ${connectedDevicePath}`);
        }
      }
    
      // Set model properties
      if (meta.meta.color) {
        const [r, g, b, a] = JSON.parse(meta.meta.color || '[]');
        modelStore.setDisplayColor(dataID, { r, g, b, a });
      }
    
      if (meta.meta.visibility) {
        modelStore.setVisibility(dataID, meta.meta.visibility === '1');
      }
    
      if (meta.meta.gp_model) {
        modelStore.setDisplayColor(dataID, { r: 0, g: 255, b: 255, a: 1 });
      }
    
      // Set measurements
      measurements.forEach((measurement) => {
        const points = measurement.points3DForVisualization || [];
        const knotPoints = measurement.knotPoints || [];
        const landmarkIds: number[] = [];
        const visualizationPoints: number[][] = [];
        const isGeodesicDist = measurement.measurementType === "GeodesicDist";
    
        points.forEach((point, index) => {
          if (isGeodesicDist) {
            visualizationPoints.push([point.x, point.y, point.z]);
          } else {
            let landmarkName;
            if (measurement.measurementType === "Landmark") {
              landmarkName = `${measurement.name}`;
            } else {
              landmarkName = `${measurement.name}_point${index + 1}`;
            }
            const landmark = landmarkStore.addLandmark(point.x, point.y, point.z, dataID, landmarkName, true, true, false);
            landmarkIds.push(landmark.id);
            groupStore.addLandmarkToGroup(dataID, landmark.id);
          }
        });
    
        if (isGeodesicDist && knotPoints) {
          knotPoints.forEach((point, index) => {
            const landmark = landmarkStore.addLandmark(point.x, point.y, point.z, dataID, `${measurement.name}_point${index + 1}`, false, false, true);
            landmarkIds.push(landmark.id);
            groupStore.addLandmarkToGroup(dataID, landmark.id);
          });
        }
    
        switch (measurement.measurementType) {
          case "Angle":
            if (landmarkIds.length === 3) {
              const angle = angleStore.addAngle(measurement.name, landmarkIds[0], landmarkIds[1], landmarkIds[2], measurement.instanceValue);
              groupStore.addAngleToGroup(dataID, angle.id);
            }
            break;
          case "EuclideanDist":
            if (landmarkIds.length === 2) {
              const line = lineStore.addLine(measurement.name, landmarkIds[0], landmarkIds[1], measurement.instanceValue);
              groupStore.addLineToGroup(dataID, line.id);
            }
            break;
          case "GeodesicDist":
            const spline = splineStore.addSpline(measurement.name, landmarkIds, false, measurement.instanceValue, visualizationPoints);
            groupStore.addSplineToGroup(dataID, spline.id);
            break;
        }
      });
    
      modelStore.refreshDisplay();
      allMeasurements.refresh();
    
      // Log the created group for debugging
      console.log(groupStore.groups[dataID]);
    },
    
    async loadBatchAnnotations(loadResults: any[], metadata: any[]) {
      loadResults.forEach((loadResult, index) => {
        this.loadAnnotation(loadResult, metadata[index]);
      });
    },
    async loadItem(itemID: string) {
      try {
        // Step 1: Fetch metadata for the item
        const { data: itemMetadata } = await this.rest.get(`/item/${itemID}`);
        
        // Step 2: Download the item
        const downloadedFile = await this.downloadItem(itemID);
    
        // Step 3: Convert the downloaded file to a data source
        const dataSource = await fileToDataSource(downloadedFile);
    
        // Step 4: Import data source and load annotations
        const loadResult = await importDataSources([dataSource]);
    
        if (!loadResult || loadResult.length === 0 || !loadResult[0].ok) {
          throw new Error('Failed to load data source');
        }
    
        // Load annotations and store paths if needed
        this.loadBatchAnnotations(loadResult, [itemMetadata]);
        this.storeGirderPaths(loadResult, [itemMetadata]);
    
        // Step 5: Set primary selection for this item
        const selection = convertSuccessResultToDataSelection(loadResult[0]);
        this.datasetStore.setPrimarySelection(selection);
    
        console.log('Item loaded successfully');
      } catch (error) {
        console.error('Failed to load item:', error);
      }
    },

    async correctData(caseId: string, progressCallback: (progress: number) => void) {
      try {
        // Get access to stores
        const modelStore = useModelStore();
        const fileStore = useFileStore();
        const labelmapStore = useLabelmapStore();
        const lmStore = useLandmarkStore();
        const paintStore = useSurface3DAnnotationStore();

        // Handle models
        if (!this.cases[caseId]) {
          throw new Error('No case loaded');
        }

        // First, handle the labelmap if it exists
        const labelmapIds = labelmapStore.idList;
        if (labelmapIds.length > 0) {
          const labelmapId = labelmapIds[0];
          const labelmap = labelmapStore.labelmaps[labelmapId];
          
          const dims = labelmap.getDimensions();
          const spacing = labelmap.getSpacing();
          const origin = labelmap.getOrigin();
          const scalarData = labelmap.getPointData().getScalars().getData();
          
          const pointsByValue = {} as any;
          
          for (let z = 0; z < dims[2]; z++) {
            for (let y = 0; y < dims[1]; y++) {
              for (let x = 0; x < dims[0]; x++) {
                const idx = x + y * dims[0] + z * dims[0] * dims[1];
                const value = scalarData[idx];
                if (value > 0) {
                  const worldX = x * spacing[0] + origin[0];
                  const worldY = y * spacing[1] + origin[1];
                  const worldZ = z * spacing[2] + origin[2];
                  
                  if (!pointsByValue[value]) {
                    pointsByValue[value] = [];
                  }
                  pointsByValue[value].push([worldX, worldY, worldZ]);
                }
              }
            }
          }

          const imageAnnotations = [{
            name: "Image Annotations",
            annotations: Object.entries(pointsByValue).map(([value, points]) => ({
              value: parseInt(value, 10),
              points: points
            }))
          }];

          const emptyFile = new File([''], 'image_annotations', { type: 'application/json' });
          const annotationItemResult = await this.uploadFile([emptyFile], caseId, 'Correct', progressCallback);

          if (annotationItemResult && annotationItemResult.length > 0) {
            await this.updateMetadata(annotationItemResult[0], {
              imageAnnotations: JSON.stringify(imageAnnotations, null, 2),
              type: 'image_annotations'
            });
          }
        }

        progressCallback(20);

        // Handle surface annotations for fitted models
        for (const id of modelStore.idList) {
          console.log('Processing model ID:', id);
          const file = fileStore.getFiles(id)[0];
          if (!file) {
            console.warn(`File not found for model: ${id}`);
            continue;
          }
          console.log('Found file:', file.name);

          const modelInGirder = this.girderPaths.find((pathObj) => pathObj.id === id);
          console.log('Model in Girder:', modelInGirder ? 'yes' : 'no', modelInGirder?.path);

          try {
            if (modelInGirder) {
              const metadata = {} as any;
              
              const lms = lmStore.serializeJSON(id);
              console.log('Landmarks for model:', id, lms ? 'available' : 'not available');
              if (lms) {
                metadata.landmarks = lms;
              }

              const surfaceLabels = paintStore.serializeJSON(id);
              console.log('Surface labels result:', surfaceLabels);
              if (surfaceLabels) {
                console.log('Surface labels content:', JSON.stringify(surfaceLabels).slice(0, 100) + '...');
                metadata.surfaceLabels = surfaceLabels;
              }

              if (Object.keys(metadata).length > 0) {
                console.log('Updating metadata for model:', { 
                  id, 
                  path: modelInGirder.path,
                  hasLandmarks: !!metadata.landmarks,
                  hasSurfaceLabels: !!metadata.surfaceLabels,
                  metadataKeys: Object.keys(metadata)
                });
                try {
                  await this.updateMetadata(modelInGirder.path, metadata);
                  console.log('Successfully updated metadata for model:', id);
                } catch (error) {
                  console.error('Failed to update metadata:', error);
                }
              }
            } else {
              // Upload new model with metadata
              console.log('Uploading new model:', id);
              const modelUploadResult = await this.uploadFile([file], caseId, 'Correct', progressCallback);
              console.log('Upload result:', modelUploadResult);

              if (modelUploadResult.length > 0) {
                const metadata = {} as any;
                
                const lms = lmStore.serializeJSON(id);
                if (lms) {
                  metadata.landmarks = lms;
                }

                const surfaceLabels = paintStore.serializeJSON(id);
                if (surfaceLabels) {
                  metadata.surfaceLabels = surfaceLabels;
                }

                if (Object.keys(metadata).length > 0) {
                  try {
                    await this.updateMetadata(modelUploadResult[0], metadata);
                    console.log('Successfully added metadata to new model:', id);
                  } catch (error) {
                    console.error('Failed to add metadata to new model:', error);
                  }
                }
              }
            }
          } catch (error) {
            console.error(`Error processing model ${id}:`, error);
          }
        }

        progressCallback(60);

        const jobDescription = 'Correct';
        await this.createJobWithInputItems(
          [],
          jobDescription,
          'Auto measurements',
          caseId
        );

        progressCallback(80);

        await this.listCases();
        this.setLoadedCase(this.cases[caseId]);

        progressCallback(100);
      } catch (error) {
        console.error('Error in correctData:', error);
        throw error;
      } finally {
        progressCallback(0);
      }
    },

    // Add new function to refresh a specific case
    async refreshCase(caseId: string) {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return null;
      }

      try {
        // Fetch the latest case data directly
        const response = await this.rest.get(`/folder/${caseId}`, {
          params: {
            limit: 1,
          },
        });

        if (response.data) {
          const caseData = response.data;
          // Update the case in our store
          this.cases[caseId] = {
            id: caseData._id,
            name: caseData.name,
            meta: caseData.meta || {},
            state: caseData.meta?.state || 'state_empty',
            created: caseData.created,
            lastUpdated: caseData.meta?.lastUpdated,
            type: ProjectType.Case,
          };

          // If this is the currently loaded case, update it too
          if (this.loadedCase?.id === caseId) {
            this.loadedCase = this.cases[caseId];
          }

          return this.cases[caseId];
        }
      } catch (error) {
        console.error('Failed to refresh case:', error);
        return null;
      }
    },

    async downloadCase(caseId: string, progressCallback: (progress: number) => void, skipDownloadedFiles: boolean = true) {
      try {
        // First refresh the case data
        const caseObj = await this.refreshCase(caseId);
        if (!caseObj) {
          console.error(`Case with ID ${caseId} not found or couldn't be refreshed.`);
          return;
        }

        // Set the loaded case immediately
        this.setLoadedCaseByID(caseId);

        // Ensure input_items and output_items are arrays
        let input_items = [];
        let output_items = [];

        try {
          if (typeof caseObj.meta.input_items === 'string') {
            input_items = JSON.parse(caseObj.meta.input_items);
          } else if (Array.isArray(caseObj.meta.input_items)) {
            input_items = caseObj.meta.input_items;
          }

          if (typeof caseObj.meta.output_items === 'string') {
            output_items = JSON.parse(caseObj.meta.output_items);
          } else if (Array.isArray(caseObj.meta.output_items)) {
            output_items = caseObj.meta.output_items;
          }
        } catch (e) {
          console.error('Error parsing items:', e);
        }

        const allItems = [...input_items, ...output_items].filter(item => item && typeof item === 'string');

        console.log(allItems);

        // Filter out files that are already downloaded if skipDownloadedFiles is true
        const itemsToDownload = allItems.filter(itemId => {
          if (!skipDownloadedFiles) return true;
          return this.girderPaths.findIndex(entry => entry.path === itemId) === -1;
        });

        // Even if no items to download, we should still update job details
        if (itemsToDownload.length === 0) {
          // Update job details if there's a job
          if (caseObj.meta?.jobId) {
            await this.updateCaseWithJobDetails(caseId);
            // Store job details in the jobDetails state
            const jobDetails = await this.getJob(caseObj.meta.jobId);
            if (jobDetails) {
              this.jobDetails[caseObj.meta.jobId] = jobDetails;
            }
          }
          progressCallback(100);
          return;
        }

        // Step 1: Fetch metadata for each item by ID
        const metadataResponses = await Promise.all(
          itemsToDownload.map(itemId => this.rest.get(`/item/${itemId}`))
        );
        const metadata = metadataResponses.map(response => response.data);

        const numberOfItems = itemsToDownload.length;
        let completedItems = 0;

        const downloadWithProgress = async (itemId: string) => {
          await this.downloadItem(itemId);
          completedItems += 1;
          const overallProgress = (completedItems / numberOfItems) * 50;
          progressCallback(overallProgress);
        };

        // Start all downloads and wait for them to complete
        await Promise.all(itemsToDownload.map(itemId => downloadWithProgress(itemId)));

        progressCallback(80);

        // Step 3: Handle the downloaded files
        const downloadedFiles = await Promise.all(
          itemsToDownload.map(itemId => this.downloadItem(itemId))
        );

        const datasources = await Promise.all(downloadedFiles.map(file => fileToDataSource(file)));

        try {
          const loadResults = await importDataSources(datasources);
          if (!loadResults || loadResults.length === 0) {
            throw new Error('Did not receive a load result');
          }

          progressCallback(85);

          this.loadBatchAnnotations(loadResults, metadata);
          this.storeGirderPaths(loadResults, metadata);

          if (!loadResults[0].ok) {
            throw new Error('Result not ok');
          }

          progressCallback(90);

          // Set primary selection based on the load results
          const imageResult = loadResults.find(
            result =>
              result.data &&
              result.data.length > 0 &&
              (result.data[0].dataType === 'dicom' || result.data[0].dataType === 'image')
          );

          if (imageResult) {
            const selection = convertSuccessResultToDataSelection(imageResult);
            this.datasetStore.setPrimarySelection(selection);
          }

          // Update case and job details
          await this.listCases(); // Refresh the cases list
          this.setLoadedCaseByID(caseId); // Set the current case again after refresh
          
          // Update job details if there's a job
          if (caseObj.meta?.jobId) {
            await this.updateCaseWithJobDetails(caseId);
            // Store job details in the jobDetails state
            const jobDetails = await this.getJob(caseObj.meta.jobId);
            if (jobDetails) {
              this.jobDetails[caseObj.meta.jobId] = jobDetails;
            }
          }

          progressCallback(100);
        } catch (error) {
          console.warn('Error loading download:', error);
          return;
        }
      } catch (error) {
        console.error('Download failed:', error);
        throw error;
      }
      progressCallback(0);
    },

    resetPaths() {
      this.girderPaths = [];
    },

    async downloadItem(itemId: string) {
      try {
        const config = {
          params: {
            format: 'zip',
            'content-disposition' : 'attachment',
          },
          responseType: 'blob',
          headers: { 'Content-Type': 'application/zip' }
        };

        const { data } = await this.rest.get(`/item/${itemId}/download`, config);
        return new File([data], `${itemId}.zip`);
      } catch (error) {
        console.error(`Download failed for item ${itemId}:`, error);
        throw error;
      }
    },

    async loadCohort(cohortId: string, progressCallback: (progress: number) => void = () => {}) {
      try {
        const cohortObj = this.cohorts[cohortId];
        if (!cohortObj) {
          console.error(`Cohort with ID ${cohortId} not found.`);
          return;
        }
    
        const output_items = cohortObj.items;

        if (!output_items || output_items.length === 0) {
          console.warn('No output items found for the cohort.');
          progressCallback(100); // Complete the progress
          return;
        }
    
        const numberOfItems = output_items.length;
        let completedItems = 0;
    
        const downloadWithProgress = async (itemId: string) => {
          completedItems += 1; // Increment the completed item count
          const overallProgress = (completedItems / numberOfItems) * 50;
          progressCallback(overallProgress); // Update progress
          return this.downloadItem(itemId);
        };

        console.log(output_items);
    
        // Step 1: Fetch metadata for each item by ID
        const metadataResponses = await Promise.all(
          output_items.map((itemId) => this.rest.get(`/item/${itemId}`))
        );
        const metadata = metadataResponses.map((response) => response.data);
    
        // Step 2: Download all items and handle progress
        const downloadedFiles = await Promise.all(
          output_items.map((itemId) => downloadWithProgress(itemId))
        );
    
        // Step 3: Convert downloaded files into data sources
        const datasources = await Promise.all(
          downloadedFiles.map((file) => fileToDataSource(file))
        );
    
        progressCallback(80);
    
        // Step 4: Import data sources and load annotations
        const loadResults = await importDataSources(datasources);
    
        if (!loadResults || loadResults.length === 0) {
          throw new Error('No data sources loaded.');
        }
    
        progressCallback(85);
    
        this.storeGirderPaths(loadResults, metadata);
        this.loadBatchAnnotations(loadResults, metadata);
    
        if (!loadResults[0].ok) {
          throw new Error('One or more results failed to load.');
        }
    
        progressCallback(90);
    
        // Step 5: Set the cohort as the current project
        this.loadedCase = {
          ...cohortObj,
          type: ProjectType.Cohort,
        };
    
        progressCallback(100);
        console.log('Cohort loaded successfully');
      } catch (error) {
        console.error('Failed to load cohort:', error);
      }
      progressCallback(0); // Reset the progress
    },

    async createCohort(cohortInfo: {
      name: string;
      anatomy: string;
      isVirtual: boolean;
      samples?: number;
      showFittedDevice?: boolean;
    }) {
      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return { success: false, message: 'Not connected to server' };
      }

      if (!this.cohortsFolder) {
        console.warn('No cohort folder selected.');
        return { success: false, message: 'No cohort folder selected.' };
      }

      const { name, anatomy, isVirtual, samples, showFittedDevice } = cohortInfo;
      const parentFolderId = this.cohortsFolder._id;

      try {
        // Create a new folder for the cohort
        const folderConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          params: {
            parentId: parentFolderId,
            name: name,
            description: `Cohort for ${anatomy} - ${isVirtual ? 'Virtual' : 'Real'}`,
          },
        };
        const { data: folderData } = await this.rest.post('/folder', null, folderConfig);

        // Add metadata to the cohort folder
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata = {
          anatomy: anatomy,
          type: isVirtual ? 'virtual' : 'real',
          state: 'state_empty',
          samples: isVirtual ? String(samples) : '0',
          prepared: 'false',
          input_items: '[]',
          output_items: '[]',
          showFittedDevice: showFittedDevice ? 'true' : 'false',
        };
        await this.rest.put(`/folder/${folderData._id}/metadata`, metadata, metadataConfig);

        // Return the created cohort data
        return {
          success: true,
          data: {
            ...folderData,
            id: folderData._id,
            meta: metadata,
            type: ProjectType.Cohort,
          },
        };
      } catch (error: any) {
        if (error.response && error.response.status === 400 && error.response.data.message.includes('A folder with that name already exists')) {
          return { success: false, message: 'A folder with that name already exists.' };
        }
        console.error('Error creating cohort:', error);
        return { success: false, message: 'An unexpected error occurred. Please try again.' };
      }
    },

    async createCase(caseInfo: { name: string; anatomy: string; anatomicalSide: string; task: string }) {

      if (!this.connected || !this.rest) {
        console.warn('Not connected to server');
        return; 
      }

      if(!this.caseFolder){
        console.warn('No case folder selected.');
        return; 
      }

      const { name, anatomy, anatomicalSide, task } = caseInfo;
      const parentFolderId = this.caseFolder._id; // The ID of the folder where cases are stored

      try {
        // Create a new folder
        const folderConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          params: {
            parentId: parentFolderId,
            name: name,
            description: `Case for ${anatomy}`,
          },
        };
        const { data: folderData } = await this.rest.post('/folder', null, folderConfig);

        // Add metadata to the folder
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata = {
          anatomy: anatomy,
          task: task,
          state: 'state_empty',
          input_items: '[]',
          output_items: '[]',
        };
        await this.rest.put(`/folder/${folderData._id}/metadata`, metadata, metadataConfig);

        // Return the created case data
        return {
          ...folderData,
          meta: metadata,
          type: ProjectType.SingleCase,
        };
      } catch (error) {
        console.error('Case creation failed:', error);
        throw error;
      }
    },
  },
});

