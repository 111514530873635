<template>
  <v-dialog 
    :model-value="modelValue" 
    @update:model-value="$emit('update:modelValue', $event)"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Create New Case</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-text-field 
            label="Case Name" 
            v-model="newCaseName" 
            required
          ></v-text-field>
          <v-select 
            label="Anatomy" 
            :items="anatomies" 
            v-model="selectedAnatomy" 
            item-title="text" 
            item-value="value"
            required
          ></v-select>
          <v-select 
            label="Task on upload" 
            :items="tasks" 
            v-model="selectedTask" 
            required
          ></v-select>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="handleCreate">
          <v-icon left>mdi-plus-box</v-icon>
          Create Case
        </v-btn>
        <v-btn @click="$emit('update:modelValue', false)">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useGirderStore } from '@/src/store/girder-store';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['update:modelValue', 'created']);

const girder = useGirderStore();
const newCaseName = ref('');
const selectedAnatomy = ref('');
const selectedTask = ref('');

const tasks = [
  'Auto measurements',
  'Prepare for simulation',
  'Reconstruct',
  'Fit device',
  'Registration',
  'Foot Fitting'
];

const anatomies = computed(() => girder.anatomies);

async function handleCreate() {
  if (!newCaseName.value || !selectedAnatomy.value || !selectedTask.value) {
    alert('Please fill all required fields.');
    return;
  }

  try {
    const caseData = await girder.createCase({
      name: newCaseName.value,
      anatomy: selectedAnatomy.value,
      anatomicalSide: 'Left',
      task: selectedTask.value,
    });

    girder.setLoadedCase(caseData);

    emit('created', caseData);
    emit('update:modelValue', false);
    
    // Reset form
    newCaseName.value = '';
    selectedAnatomy.value = '';
    selectedTask.value = '';
  } catch (error) {
    console.error('Failed to create case:', error);
  }
}
</script> 