<template>
  <div class="mt-4">
    <p class="pl-4"><strong>Job ID:</strong> {{ jobDetails.jobId }}</p>
    <p class="pl-4"><strong>Current progress:</strong> {{ formatProgressMessage(jobDetails.progress?.message) }}</p>
    
    <div class="log-section pl-4">
      <div class="d-flex align-center justify-space-between mb-2">
        <span class="text-subtitle-1">Log Output</span>
        <v-btn icon @click="$emit('show-log')">
          <v-icon>mdi-arrow-expand</v-icon>
        </v-btn>
      </div>
      <pre class="log-content">
        <code>{{ jobLog }}</code>
      </pre>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  jobDetails: {
    type: Object,
    required: true
  },
  jobLog: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['show-log']);

const formatProgressMessage = (message) => {
  return message || 'No progress available';
};
</script>

<style scoped>
.log-section {
  margin-top: 16px;
}

.text-subtitle-1 {
  font-size: 1rem;
  font-weight: 500;
}

.log-content {
  margin: 0;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  font-family: 'Consolas', 'Monaco', monospace;
  font-size: 0.875rem;
}

code {
  font-family: inherit;
}
</style> 