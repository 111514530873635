import { defineStore } from 'pinia';
import { useLandmarkStore } from './tools/landmarks';
import { useLineStore } from './tools/lines';
import { useAngleStore } from './tools/angles';
import { useSplineStore } from './tools/splines';
import { useModelStore } from './datasets-models';

interface Group {
  parentName: string; // modelID
  landmarks: number[];
  lines: number[];
  angles: number[];
  splines: number[];
  surfaceMeasurements: number[];
  subModels: string[];
}

export const useGroupStore = defineStore('group', {
  state: () => ({
    groups: {} as { [modelId: string]: Group },
  }),
  actions: {
    createGroup(modelId: string) {
      if (!this.groups[modelId]) {
        this.groups[modelId] = {
          parentName: modelId,
          landmarks: [],
          lines: [],
          angles: [],
          splines: [],
          surfaceMeasurements: [],
          subModels: [],
        };
      }
    },

    // Add subModel to group
    addSubModelToGroup(modelId: string, subModelId: string) {
      if (this.groups[modelId]) {
        if (!this.groups[modelId].subModels.includes(subModelId)) {
          this.groups[modelId].subModels.push(subModelId);
        }
      }
    },

    addLandmarkToGroup(modelId: string, landmarkId: number) {
      if (this.groups[modelId]) {
        this.groups[modelId].landmarks.push(landmarkId);
      }
    },
    addLineToGroup(modelId: string, lineId: number) {
      if (this.groups[modelId]) {
        this.groups[modelId].lines.push(lineId);
      }
    },
    addAngleToGroup(modelId: string, angleId: number) {
      if (this.groups[modelId]) {
        this.groups[modelId].angles.push(angleId);
      }
    },
    addSplineToGroup(modelId: string, splineId: number) {
      if (this.groups[modelId]) {
        this.groups[modelId].splines.push(splineId);
      }
    },
    addSurfaceMeasurementToGroup(modelId: string, measurementId: number) {
      if (this.groups[modelId]) {
        this.groups[modelId].surfaceMeasurements.push(measurementId);
      }
    },

    // Retrieve actors for group
    getActorsForGroup(modelId: string) {
      const actors: any[] = [];
      const landmarkStore = useLandmarkStore();
      const lineStore = useLineStore();
      const angleStore = useAngleStore();
      const splineStore = useSplineStore();

      if (this.groups[modelId]) {
        const group = this.groups[modelId];

        // Get landmark actors
        group.landmarks.forEach(landmarkId => {
          const actor = landmarkStore.actors.get(landmarkId);
          if (actor) {
            actors.push(actor);
          }
        });

        // Get line actors
        group.lines.forEach(lineId => {
          const lineActor = lineStore.lineActors.get(lineId);
          if (lineActor && lineActor.actor) {
            actors.push(lineActor.actor);
          }
        });

        // Get angle actors
        group.angles.forEach(angleId => {
          const actor = angleStore.angleActors.get(angleId);
          if (actor) {
            actors.push(actor);
          }
        });

        // Get spline actors
        group.splines.forEach(splineId => {
          const splineActor = splineStore.splineActors.get(splineId);
          if (splineActor && splineActor.actor) {
            actors.push(splineActor.actor);
          }
        });
      }

      return actors;
    },

    // Retrieve subModels for group
    getSubModelsForGroup(modelId: string) {
      const modelStore = useModelStore();
      const subModels: any[] = [];

      if (this.groups[modelId]) {
        const group = this.groups[modelId];
        group.subModels.forEach(subModelId => {
          const model = modelStore.dataIndex[subModelId];
          if (model) {
            subModels.push(model);
          }
        });
      }

      return subModels;
    },

    // Retrieve measurements for group
    getMeasurementsForGroup(modelId: string) {
      const measurements: { name: string; type: string; value: string }[] = [];
      const landmarkStore = useLandmarkStore();
      const lineStore = useLineStore();
      const angleStore = useAngleStore();
      const splineStore = useSplineStore();

      if (this.groups[modelId]) {
        const group = this.groups[modelId];

        // Get line measurements
        group.lines.forEach(lineId => {
          const line = lineStore.lines.find(l => l.id === lineId);
          if (line) {
            measurements.push({
              name: line.name,
              type: 'Line',
              value: `${line.distance.toFixed(2)} mm`,
            });
          }
        });

        // Get angle measurements
        group.angles.forEach(angleId => {
          const angle = angleStore.angles.find(a => a.id === angleId);
          if (angle) {
            measurements.push({
              name: angle.name,
              type: 'Angle',
              value: `${angle.value.toFixed(2)}°`,
            });
          }
        });

        // Get spline measurements
        group.splines.forEach(splineId => {
          const spline = splineStore.splines.find(s => s.id === splineId);
          if (spline) {
            measurements.push({
              name: spline.name,
              type: 'Spline',
              value: `${spline.length.toFixed(2)} mm`,
            });
          }
        });
      }

      return measurements;
    },
  },
});
